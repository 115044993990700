<template>
  <div>
    <b-row class="mb-2">
      <b-col md="5">
        <validation-provider
          #default="{ errors }"
          name="name"
          rules="required"
        >
          <b-form-group :label="$t('Schedule Name')">
            <b-form-input
              v-model="name"
              :placeholder="$t('Schedule Name')"
              type="text"
              @change="$emit('remarks', 'name', name)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="12">
        <validation-provider
          #default="{ errors }"
          name="description"
          rules="required"
        >
          <b-form-group :label="$t('Schedule Description')">
            <b-form-textarea
              v-model="description"
              :placeholder="$t('Schedule Description')"
              type="text"
              rows="3"
              @change="$emit('remarks', 'description', description)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <schedule-card
      :schedule-data="previewData"
      :preview="true"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { reactive, toRefs } from 'vue'
import ScheduleCard from './ScheduleCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ScheduleCard,
  },
  props: {
    previewData: {
      type: Object,
      required: true,
    },
    currentData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    currentData: {
      handler(val) {
        if (val) {
          this.name = this.currentData.name
          this.description = this.currentData.description
        }
      },
      immediate: true,
    },
  },
  setup() {
    const state = reactive({
      name: '',
      description: '',
    })

    return {
      ...toRefs(state),
    }
  },
}
</script>
