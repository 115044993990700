<template>
  <div class="d-flex align-items-baseline">
    <div
      class="flex-shrink-1 pr-0 text-right"
    >
      <b-button
        :id="tourKeyword"
        variant="flat-secondary"
        class="p-1"
        @click="callback ? $emit('back') : routeName ? $router.replace({ name: routeName, params, queries }) : $router.go(-1)"
      >
        <feather-icon
          icon="ArrowLeftIcon"
          size="15"
        />
        {{ $t('Back') }}
      </b-button>
    </div>

    <div
      class="pl-0"
    >
      <h4 class="pl-1 mb-0 border-left border-2">
        {{ title }}
      </h4>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    routeName: {
      type: String,
      default: () => null,
    },
    params: {
      type: Object,
      default: () => {},
    },
    queries: {
      type: Object,
      default: () => {},
    },
    callback: {
      type: Boolean,
      default: () => false,
    },
    tourKeyword: {
      type: String,
      default: () => null,
    },
  },
}
</script>
