<template>
  <div>
    <!-- Desktop View -->
    <h2 class="d-none d-md-block">
      {{ $t('Transfer Type') }}
    </h2>
    <!-- Mobile View -->
    <h4 class="d-md-none">
      {{ $t('Transfer Type') }}
    </h4>
    <radio-form
      v-model="transferType"
      name="transferType"
      :options="transferTypeOptions"
      :selected="transferType"
      @input="$emit('amount-setting', 'transferType', transferType)"
    />

    <div :class="IS_MOBILE() ? 'w-100' : 'w-50'">
      <validation-provider
        #default="{ errors }"
        :name="transferType === 'PERCENT' ? 'transfer percentage' : 'transfer amount'"
        :rules="`required|min_value:1|wz-double|${transferType === 'PERCENT' ? 'percent' : ''}`"
      >
        <b-form-group :label="transferType === 'PERCENT' ? $t('Transfer Percentage') : $t('Transfer Amount')">
          <b-input-group
            :prepend="transferType === 'FIXED' ? $store.state.project.company.currency : ''"
            :append="transferType === 'PERCENT' ? '%' : ''"
          >
            <b-form-input
              v-model="transferAmount"
              type="number"
              @change="$emit('amount-setting', 'transferAmount', transferAmount)"
              @keypress="handleAmountInput($event)"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import RadioForm from '@/views/common/components/RadioForm.vue'
import { ValidationProvider, extend } from 'vee-validate'
import { BFormGroup, BInputGroup, BFormInput } from 'bootstrap-vue'
import { getCurrentInstance, reactive, toRefs } from 'vue'

extend('percent', value => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) return 'Transfer percent should be a number'
  if (value >= 0 && value <= 100) return true
  return 'Transfer percent should be between 0 and 100'
})

extend('wz-double', value => {
  const decimals = value.toString().split('.')[1]
  if (decimals && decimals.length > 2) return 'The amount should have atmost 2 decimal points'
  return true
})

export default {
  components: {
    RadioForm,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BInputGroup,
  },
  props: {
    currentData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    currentData: {
      handler(val) {
        if (val) {
          this.transferType = this.currentData.transferType
          this.transferAmount = this.currentData.transferAmount
        }
      },
      immediate: true,
    },
  },
  setup() {
    const root = getCurrentInstance().proxy.$root
    const store = root.$store
    const state = reactive({
      transferType: 'PERCENT',
      transferAmount: 0,
      transferTypeOptions: [
        { label: `${root.$t('Percentage')} (%)`, value: 'PERCENT', details: root.$t('messages.percent-transfer-info') },
        { label: `${root.$t('Fixed')} (${store.state.project.company.currency})`, value: 'FIXED', details: root.$t('messages.fixed-transfer-info') },
      ],
    })

    const handleAmountInput = event => {
      const inputValue = event.target.value
      const decimalParts = inputValue.split('.')

      if (decimalParts.length > 1 && decimalParts[1].length > 1) {
        event.preventDefault()
      }
      state.transferAmount = event.target.value
    }

    return {
      ...toRefs(state),
      handleAmountInput,
    }
  },
}
</script>
