<template>
  <b-modal
    :id="modal"
    :title="title"
    :ok-title="okTitle ?? $t('Confirm')"
    :ok-variant="okVariant"
    :ok-disabled="okDisabled"
    :cancel-disabled="cancelDisabled"
    :cancel-title="cancelTitle ?? $t('Cancel')"
    no-close-on-backdrop
    @ok="$emit('confirm', params)"
    @cancel="$emit('cancel')"
    @hidden="$emit('hidden')"
  >
    <b-alert
      v-if="alert"
      class="p-1"
      :variant="alertVariant === 'danger' ? 'none' : alertVariant"
      show
    >
      <b-row>
        <b-col
          cols="2"
          class="px-0"
        >
          <feather-icon
            :icon="icon ? icon : alertVariant === 'danger' ? 'AlertTriangleIcon' : 'InfoIcon'"
            :size="iconSize"
            :color="getVariant"
          />
        </b-col>
        <b-col class="pr-0">
          {{ alert }}
        </b-col>
      </b-row>
    </b-alert>
    <p>{{ description }}</p>
    <slot />
  </b-modal>
</template>

<script>
import {
  BModal, BAlert, BRow, BCol,
} from 'bootstrap-vue'
import props from './confirmModalProps'

export default {
  components: {
    BModal,
    BAlert,
    BRow,
    BCol,
  },
  props,
  computed: {
    getVariant() {
      switch (this.iconColor) {
        case 'danger':
          return '#ea5455'
        case 'success':
          return '#28c76f'
        default:
          return this.iconColor
      }
    },
  },
}
</script>
